.home {
    width: 100%;
    padding: 1.5rem;
    color: white;
    margin: auto;
    text-align: justify;
}

.home img {
    margin: auto;
    border-radius: 50%;
    max-height: 400px;
    max-width: 400px;
    display: flex;
}

.about {
    padding: 2.5rem;
    margin: auto;
    justify-content: center;
    width: 100%;
}

.about ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.links {
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 2rem;
}

.links a, .links path {
    padding: 0 4rem;
    width: 3rem;
    color: white;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .home img {
        max-height: 300px;
        max-width: 300px;
    } 
}

@media screen and (min-width: 375px) and (max-width: 768px) {
    .home img {
        max-height: 200px;
        max-width: 200px;
    }

    .links a, .links path {
        padding: 0 2.5rem;
        width: 3rem;
    }
}