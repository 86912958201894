.Resume {
  color: white;
  height: 100%;
}

.card {
  height: 50%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 2.5rem auto;
  background-color: #2f4050 !important;
  border-radius: 2%;
  /* font-size: 1.2rem; */
}

.keySkills {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.content {
  padding: 0 1.5rem;
}

.content h2 {
  display: flex;
  justify-content: center;
}

.btn.btn-primary {
  display: flex;
  margin: 2.5rem auto;
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .content h3,
  .content h4,
  .content h5 {
    display: flex;
    justify-content: center;
  }
}
